import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useQueryParam, encodeString, decodeString } from "use-query-params";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  top: 0;
  left: 0;
  right: 0;

  line-height: 120px;

  background-color: ${props => props.backgroundColor};
  transition: 150ms background-color ease-in-out;

  z-index: 200;

  & a {
    text-decoration: none;

    &.current {
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  position: relative;

  // &.show-logo {
  //   position: relative;
  //   height: auto;
  //   padding: 0;
  // }

  // &.hide-logo {
  //   position: sticky;
  //   height: 120px;
  //   top: 0;

  //   @media (max-width: 900px) {
  //     position: fixed;
  //     padding: 15px;

  //     height: auto;
  //   }
  // }

  @media (max-width: 900px) {
    line-height: 55px;
  }
`;

export const Header = ({ location, isDesktop, backgroundColor, pageType }) => {
  const MyStringParam = {
    encode: str => {
      // by replacing these values with undefined, they are removed from the URL
      if (str === "" || str == null) return undefined;

      return encodeString(str);
    },
    decode: urlStr => {
      const decoded = decodeString(urlStr);

      // coerce nully values to empty string for usage in app
      if (decoded == null) return "";
      return decoded;
    },
  };

  const [openFilter, setOpenFilter] = useState(null);
  const [selectedType, setSelectedType] = useQueryParam("what", MyStringParam);
  const [selectedPlace, setSelectedPlace] = useQueryParam(
    "where",
    MyStringParam
  );

  const data = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          header_links {
            header_link {
              document {
                ... on PrismicAbout {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicGetInvolved {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPartners {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicProgramme {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicProject {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicDraw {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicMap {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicGlossary {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
      prismicProgramme {
        data {
          projects {
            project {
              document {
                ... on PrismicProject {
                  id
                  data {
                    places {
                      place
                    }
                    types {
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const where = Array.from(
    new Set(
      data.prismicProgramme.data.projects
        .filter(project => project.project.document !== null)
        .map(project =>
          project.project.document.data.places
            .filter(place => place.place !== "")
            .map(place => place.place)
        )
        .flat()
        .filter(place => place !== null)
        .map(place => place.toLowerCase())
    )
  ).map((place, index) => (
    <li key={`where${index}`}>
      {location.includes(`/projects/`) ? (
        <Link to={`/?where=${place}`}>{place}</Link>
      ) : (
        <button
          onClick={() => {
            setSelectedPlace(place);
            setOpenFilter(null);
          }}
        >
          {place}
        </button>
      )}
    </li>
  ));

  const what = Array.from(
    new Set(
      data.prismicProgramme.data.projects
        .filter(project => project.project.document !== null)
        .map(project =>
          project.project.document.data.types
            .filter(type => type.type !== "")
            .map(type => type.type)
        )
        .flat()
        .filter(type => type !== null)
        .map(type => type.toLowerCase())
    )
  ).map((type, index) => (
    <li key={`single_homepage_filter_what_${index}`}>
      {location.includes(`/projects/`) ? (
        <Link to={`/?what=${type}`}>{type}</Link>
      ) : (
        <button
          onClick={() => {
            setSelectedType(type);
            setOpenFilter(null);
          }}
        >
          {type}
        </button>
      )}
    </li>
  ));

  const drawLink = {
    header_link: {
      document: {
        url: "/draw",
        data: {
          title: {
            text: "Draw",
          },
        },
      },
    },
  };

  return (
    <HeaderWrapper backgroundColor={backgroundColor}>
      {isDesktop ? (
        <>
          <DesktopMenu
            links={data.prismicSettings.data.header_links}
            location={location}
            where={where}
            what={what}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            pageType={pageType}
            selectedType={selectedType}
            selectedPlace={selectedPlace}
            setSelectedType={setSelectedType}
            setSelectedPlace={setSelectedPlace}
            backgroundColor={backgroundColor}
          />
        </>
      ) : (
        <MobileMenu
          links={data.prismicSettings.data.header_links}
          location={location}
          where={where}
          what={what}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          pageType={pageType}
          selectedType={selectedType}
          selectedPlace={selectedPlace}
          setSelectedType={setSelectedType}
          setSelectedPlace={setSelectedPlace}
          backgroundColor={backgroundColor}
        />
      )}
    </HeaderWrapper>
  );
};
