import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

// Components
import { Logo } from "../icons/logo";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  flex: 100%;
  max-width: 100%;
  width: 100%;

  padding: 40px 0 0 0;

  & svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  &.fixed {
    position: fixed;
    background: ${props => props.backgroundColor};
    left: 0;
    right: 0;
    padding: 0 40px;
  }

  & ol {
    & li {
      margin: 0 50px 0 0;

      & ol.submenu {
        & li {
          margin: 0;
        }
      }

      &.filters-list.what-filters {
        margin: 0;
      }
    }
  }

  & .filters {
    & li {
      position: relative;

      font-size: 16px;
      line-height: 23px;

      & a,
      & button {
        display: inline-block;

        padding: 5px 10px;
        margin: 0 0 10px 0;

        background: #fff;
        border-radius: 3px;

        text-transform: capitalize;
        text-decoration: none;

        cursor: pointer;

        &:hover {
          background-color: #000;
          color: #fff;
        }

        &.active {
          background-color: #000;
          color: #fff;
        }
      }

      & ol.submenu {
        position: absolute;
        width: max-content;
      }
    }
  }
`;

export const DesktopMenu = ({
  links,
  location,
  where,
  what,
  openFilter,
  setOpenFilter,
  pageType,
  selectedType,
  selectedPlace,
  setSelectedType,
  setSelectedPlace,
  backgroundColor,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const content = links.map((link, index) => {
    if (link.header_link.document.url === `/`) {
      return (
        <li
          key={`single_desktop_header_${link.header_link.document.id}_${index}`}
        >
          <Link
            to={`/`}
            className={location.includes(`/projects/`) ? `current` : ``}
          >
            {link.header_link.document.data.title.text}
          </Link>
        </li>
      );
    } else {
      return (
        <li
          key={`single_desktop_header_${link.header_link.document.id}_${index}`}
        >
          <Link
            to={`${link.header_link.document.url}/`}
            activeClassName="current"
          >
            {link.header_link.document.data.title.text}
          </Link>
        </li>
      );
    }
  });

  useEffect(() => {
    setOpenFilter(null);
  }, [location]);

  const Navigation = ({ className }) => (
    <Nav className={className} backgroundColor={backgroundColor}>
      <ol className="flex row">{content}</ol>
    </Nav>
  );

  return (
    <HeaderContainer>
      <LogoContainer ref={ref}>
        <Logo />
      </LogoContainer>

      <Navigation className={""} />

      {!inView && <Navigation className={"fixed"} />}
    </HeaderContainer>
  );
};
