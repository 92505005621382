import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import { SignUpForm } from "../forms/signup-form";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 70px 0;
  background-color: ${props => props.backgroundColor};
  transition: 150ms background-color ease-in-out;

  & .left-column {
    flex-wrap: wrap;
  }

  & ol {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    & li {
      margin-right: 40px;

      & p {
        margin: 0;
      }

      @media (max-width: 768px) {
        margin: 0 30px 0 0;

        &:first-of-type {
          width: 100%;
          margin: 0 0 25px 0;
        }

        & p {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0;
        }
      }
    }
  }

  & a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    padding: 20px 0 30px 0;

    & .left-column {
      width: 100%;
    }

    & .privacy-policy-link {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const Footer = ({ backgroundColor }) => {
  const data = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          footer_links {
            footer_link {
              html
            }
          }
        }
      }
    }
  `);

  const content = data.prismicSettings.data.footer_links.map((link, index) => (
    <li key={`single_footer_${index}`}>
      <div
        className="text"
        dangerouslySetInnerHTML={{
          __html: link.footer_link.html,
        }}
      />
    </li>
  ));

  return (
    <FooterWrapper backgroundColor={backgroundColor}>
      <div className="flex row left-column">
        <ol>{content}</ol>

        <SignUpForm />
      </div>

      <Link to={`/privacy-policy/`} className="privacy-policy-link">
        Privacy policy
      </Link>
    </FooterWrapper>
  );
};
