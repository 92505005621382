import React, { createContext, useContext, useState } from "react";

const ColorContext = createContext();

export const useColorContext = () => {
  return useContext(ColorContext);
};

export const ColorProvider = ({ children }) => {
  const colors = [
    "#b6ffff",
    "#f8c2ff",
    "#ffff8f",
    "#ffb592",
    "#c0ffc9",
    "#ffccd4",
    "#cfbe99",
  ];
  const [randomColors, setRandomColors] = useState([]);
  const [previousColors, setPreviousColors] = useState([]);

  const pickRandomColors = () => {
    const availableColors = colors.filter(
      color => !previousColors.includes(color)
    );

    if (availableColors.length < 2) {
      // If not enough available colors, reset the previousColors array
      setPreviousColors([]);
    }

    const randomIndex1 = Math.floor(Math.random() * availableColors.length);
    let randomIndex2 = Math.floor(Math.random() * availableColors.length);

    while (randomIndex2 === randomIndex1) {
      randomIndex2 = Math.floor(Math.random() * availableColors.length);
    }

    const newRandomColors = [
      availableColors[randomIndex1],
      availableColors[randomIndex2],
    ];
    setRandomColors(newRandomColors);
    setPreviousColors(newRandomColors);
  };

  const contextValue = {
    randomColors,
    pickRandomColors,
  };

  return (
    <ColorContext.Provider value={contextValue}>
      {children}
    </ColorContext.Provider>
  );
};
