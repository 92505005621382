import React from "react";
import styled from "styled-components";

// Icons
import { Logo } from "../icons/logo";
import { MobileLogo } from "../icons/mobile-logo";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.background};
  z-index: 10000;

  opacity: ${props => props.opacity};
  transition: 300ms opacity ease;

  & .container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: 300ms all ease;
  }

  & svg {
    width: calc(100vw - 40px - 40px);
    height: auto;

    display: block;

    @media (max-width: 768px) {
      width: calc(100vw - 15px - 15px);
    }
  }

  &.slide-up {
    & .container {
      top: 0;
      left: 50%;
      transform: translate(-50%, 40px);
    }
  }
`;

export const Splash = ({
  background,
  slideUpSplash,
  showSplash,
  isDesktop,
}) => {
  return (
    <SplashContainer
      background={background}
      className={slideUpSplash ? `slide-up` : `slide-down`}
      opacity={showSplash ? 1 : 0}
    >
      <div className="container">{isDesktop ? <Logo /> : <MobileLogo />}</div>
    </SplashContainer>
  );
};
