import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";

// Context
import { useColorContext } from "../context/color-context";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Splash } from "./splash";

const Container = styled.div`
  position: relative;

  background-color: ${props => props.backgroundColor};
  transition: 150ms background-color ease-in-out;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;
  padding: 0 40px;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  &.page-map {
    padding: 0;

    & header {
      padding: 0 40px;

      @media (max-width: 768px) {
        padding: 0 15px;
      }
    }
  }
`;

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, children, isDesktop, pageType }) => {
  const { randomColors, pickRandomColors } = useColorContext();

  useEffect(() => {
    pickRandomColors();
  }, [location]);

  // Splash
  const [showSplash, setShowSplash] = useState(true);
  const [slideUpSplash, setSlideUpSplash] = useState(false);
  const [removeSplash, setRemoveSplash] = useState(false);

  useEffect(() => {
    const slideUpSplashTimer = setTimeout(() => {
      setSlideUpSplash(true);

      const hideSplashTimer = setTimeout(() => {
        setShowSplash(false);

        const removeSplashTimer = setTimeout(() => {
          setRemoveSplash(true);
        }, 500);
        return () => clearTimeout(removeSplashTimer);
      }, 500);
      return () => clearTimeout(hideSplashTimer);
    }, 2500);
    return () => clearTimeout(slideUpSplashTimer);
  }, []);

  return (
    <Container
      location={location.pathname}
      className={`page-${pageType}`}
      backgroundColor={`#e5e5e5`}
    >
      <Header
        location={location}
        isDesktop={isDesktop}
        backgroundColor={`#e5e5e5`}
        pageType={pageType}
      />
      <Main>{children}</Main>

      {pageType !== "map" && (
        <Footer
          location={location}
          isDesktop={isDesktop}
          backgroundColor={`#e5e5e5`}
          pageType={pageType}
        />
      )}

      {!removeSplash && (
        <RemoveScroll>
          <Splash
            background={`#e5e5e5`}
            slideUpSplash={slideUpSplash}
            showSplash={showSplash}
            isDesktop={isDesktop}
          />
        </RemoveScroll>
      )}
    </Container>
  );
};
