import * as React from "react";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import Layout from "./src/components/global/layout";

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
);

// Wrap all pages with the Layout component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
