import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

// Icons
import { MobileLogo } from "../icons/mobile-logo";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  flex: 100%;
  max-width: 100%;
  width: 100%;

  padding: 40px 0 25px 0;

  & svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Nav = styled.nav`
  width: 100%;
  padding: 15px 0;

  &.fixed {
    width: auto;

    position: fixed;
    background: ${props => props.backgroundColor};
    left: 0;
    right: 0;
    padding: 15px 40px;

    @media (max-width: 768px) {
      padding: 15px;
    }
  }

  & .menu-container {
    width: 100%;

    & .top-line-container {
      width: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  & ol.menu {
    display: block;
    padding: 30px 0 0 0;

    & li {
      width: 100%;
      line-height: 23px;

      & a {
        display: block;
        margin: 0 0 30px 0;

        &.current {
          display: none;
          margin: 0;
        }
      }
    }
  }

  & .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 10px 0 0 0;

    & li {
      position: relative;

      font-size: 14px;
      line-height: 18px;

      margin: 0 10px 0 0;

      & a,
      & button {
        display: inline-block;

        padding: 5px 10px;
        // margin: 0 0 10px 0;

        background: #fff;
        border-radius: 3px;

        text-transform: capitalize;
        text-decoration: none;

        cursor: pointer;

        &:hover {
          background-color: #000;
          color: #fff;
        }

        &.active {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }

  &.fixed {
    & ol.submenu {
      padding: 15px 15px 0 15px;
    }
  }

  & ol.submenu {
    position: absolute;

    left: 0;
    right: 0;

    padding: 10px 0 0 0;

    background: ${props => props.backgroundColor};

    & li {
      position: relative;

      font-size: 14px;
      line-height: 18px;

      margin: 0 10px 0 0;

      & a,
      & button {
        display: inline-block;

        padding: 5px 10px;
        margin: 0 0 10px 0;

        background: #fff;
        border-radius: 3px;

        text-transform: capitalize;
        text-decoration: none;

        cursor: pointer;

        &:hover {
          background-color: #000;
          color: #fff;
        }

        &.active {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
`;

export const MobileMenu = ({
  links,
  location,
  where,
  what,
  openFilter,
  setOpenFilter,
  pageType,
  selectedType,
  selectedPlace,
  setSelectedType,
  setSelectedPlace,
  backgroundColor,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState(``);

  useEffect(() => {
    setIsMenuOpen(false);

    if (location.includes(`/projects`) || location === `/`) {
      setPageTitle(`Projects`);
    } else if (location.includes(`/partners`)) {
      setPageTitle(`Partners`);
    } else if (location.includes(`/get-involved`)) {
      setPageTitle(`Get Involved`);
    } else if (location.includes(`/about`)) {
      setPageTitle(`About`);
    } else if (location.includes(`/draw`)) {
      setPageTitle(`Draw`);
    } else if (location.includes(`/map`)) {
      setPageTitle(`Map`);
    } else if (location.includes(`/glossary`)) {
      setPageTitle(`Glossary`);
    }
  }, [location]);

  const content = links.map((link, index) => {
    if (link.header_link.document.url === `/`) {
      return (
        <li
          key={`single_desktop_header_${link.header_link.document.id}_${index}`}
        >
          <Link
            to={`/`}
            activeClassName="current"
            className={
              location.includes(`/projects/`) &&
              link.header_link.document.data.title.text === "Projects"
                ? `current`
                : ``
            }
          >
            {link.header_link.document.data.title.text}
          </Link>
        </li>
      );
    } else {
      return (
        <li
          key={`single_desktop_header_${link.header_link.document.id}_${index}`}
        >
          <Link
            to={`${link.header_link.document.url}/`}
            activeClassName="current"
            className={
              location.includes(`/projects/`) &&
              link.header_link.document.data.title.text === "Projects"
                ? `current`
                : ``
            }
          >
            {link.header_link.document.data.title.text}
          </Link>
        </li>
      );
    }
  });

  const Navigation = ({ className }) => (
    <Nav backgroundColor={backgroundColor} className={className}>
      <div className="menu-container">
        <div className="top-line-container flex">
          {location.includes(`/projects/`) ? (
            <h1>
              <Link to={`/`}>{pageTitle}</Link>
            </h1>
          ) : (
            <h1>{pageTitle}</h1>
          )}
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>Menu</button>
        </div>
        {isMenuOpen && <ol className="menu">{content}</ol>}
      </div>
    </Nav>
  );

  return (
    <HeaderContainer>
      <LogoContainer ref={ref}>
        <MobileLogo />
      </LogoContainer>
      <Navigation className={""} />
      {!inView && <Navigation className={"fixed"} />}
    </HeaderContainer>
  );
};
