import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Context
import { PageType } from "../context/page-type";
import { ColorProvider } from "../context/color-context";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Layout = ({ children }) => {
  const [pageType, setPageType] = useState(``);
  let isDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <ColorProvider>
      <Location>
        {({ location }) => {
          return (
            <PageType.Provider value={[pageType, setPageType]}>
              <Normalize />
              <GlobalStyles />
              <DefaultSEO location={location} />
              <App
                location={location.pathname}
                children={children}
                isDesktop={isDesktop}
                pageType={pageType}
              />
            </PageType.Provider>
          );
        }}
      </Location>
    </ColorProvider>
  );
};

export default Layout;
