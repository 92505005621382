import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Components

const EnquiryFormContainer = styled.div`
  position: relative;
  max-width: 370px;

  color: #000;

  @media (max-width: 600px) {
    margin: 20px 0;
    width: 100%;
    max-width: 100%;
  }

  & .thank-you-message,
  & form {
    max-width: 370px;
    margin: 0;
    align-items: baseline;

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
      width: calc(370px - 141px);

      border-bottom: 1px solid #000;

      & input {
        width: 100%;

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background-color: transparent;
        transition: 1500ms color ease;

        font-size: 19px;
        line-height: 29px;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 18px;
        }

        &:focus,
        &:active {
          outline: none;
        }

        ::-webkit-input-placeholder {
          font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande",
            sans-serif;
          color: #646464 !important;
        }
        ::-moz-placeholder {
          font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande",
            sans-serif;
          color: #646464 !important;
        }
        :-ms-input-placeholder {
          font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande",
            sans-serif;
          color: #646464 !important;
        }
        :-moz-placeholder {
          font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande",
            sans-serif;
          color: #646464 !important;
        }
      }
    }
  }

  & .thank-you-message {
    & p {
      margin: 0;
    }
  }
`;

const SubmitButtonContainer = styled.div`
  & .top-line-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    margin: 0 0 0 40px;

    & p {
      margin: 0;
    }

    @media (max-width: 600px) {
      margin: 0 0 0 10px;
    }

    & button {
      background-color: #ffffff;
      border: 0;
      border-radius: 3px;

      text-align: center;
      font-size: 18px;
      line-height: 23px;

      cursor: pointer;

      padding: 4px 10px;

      color: #000;
      transition: 250ms all ease;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }

      &:hover,
      &:focus,
      &:focus-within {
        color: #fff;
        background-color: #000;
      }

      &:disabled,
      &[disabled] {
        &:hover,
        &:focus,
        &:focus-within {
          color: #000;
          background-color: transparent;
        }
      }
    }
  }
`;

export const SignUpForm = () => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState("Invalid Email");
  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  // Form Setup
  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    formState,
    getValues,
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setMailchimpMessage(
              "Thank you for signing up to the Three Rivers mailing list."
            );
            setIsFormVisible(false);
            e.target.reset();
          }
        } else {
          setMailchimpMessage(
            "Thank you for signing up to the Three Rivers mailing list."
          );
          setIsFormVisible(false);
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const fadeOutForm = setTimeout(() => {
        const resetFormVisbility = setTimeout(() => {
          setIsFormVisible(true);
        }, 1000);
        return () => clearTimeout(resetFormVisbility);
      }, 8000);
      return () => clearTimeout(fadeOutForm);
    }
  }, [isFormVisible]);

  // Read the formState before render to subscribe the form state through Proxy
  const { dirtyFields } = formState;

  return (
    <EnquiryFormContainer>
      {isFormVisible && (
        <>
          <form
            name="signup-form"
            id="holding_site_signup_form"
            className="flex row"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="signup-form" />

            <div className="field">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="E-mail"
                placeholder={
                  errors.email
                    ? `Enter a valid e-mail address`
                    : `sign up to our newsletter`
                }
                ref={register({
                  required: true,
                })}
              />
            </div>

            <SubmitButtonContainer>
              <div className="top-line-container">
                <button type="submit" className="submit-button" tabIndex="0">
                  Subscribe
                </button>
              </div>
            </SubmitButtonContainer>
          </form>

          <div className="errors-container">
            {(errors.fName || errors.lName || errors.email) && (
              <p>Please complete the missing information</p>
            )}
          </div>
        </>
      )}

      {!isFormVisible && (
        <div className="thank-you-message">
          <p>{mailchimpMessage}</p>
        </div>
      )}
    </EnquiryFormContainer>
  );
};
