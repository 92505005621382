import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.loading {
  body {
    opacity: 0;
    transition: 150ms background-color ease-in-out, 150ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

html {
  background-color: #e5e5e5;
}

body {
  font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 22px;
  line-height: 29px;

  background-color: #e5e5e5;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  transition: 150ms background-color ease-in-out, 150ms opacity ease;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 23px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1, h2, h3{
  font-size: 22px;
  line-height: 29px;

  @media(max-width: 768px){
    font-size: 18px;
    line-height: 23px;
  }
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border: 0;
    appearance:none;
    padding:0;
    margin:0;

    cursor: pointer;
    color: #000;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  position: relative;
  text-underline-offset: 5.5px;
  text-decoration-thickness: 1px;

  @media (max-width: 768px) {
    text-underline-offset: 4px;
  }
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  // margin: 1em 0;
  margin: 29px 0;

  @media (max-width: 768px) {
    margin: 23px 0;
  }
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

strong {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-style:normal;
  font-weight:normal;
}

.uppercase{
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

::-webkit-input-placeholder{
  font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande", sans-serif;
  color: #646464 !important;

  @media(max-width: 768px){
    font-size: 14px;
  }
}

::-moz-placeholder{
  font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande", sans-serif;
  color: #646464 !important;

  @media(max-width: 768px){
    font-size: 14px;
  }
}


:-ms-input-placeholder{
  font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande", sans-serif;
  color: #646464 !important;

  @media(max-width: 768px){
    font-size: 14px;
  }
}

:-moz-placeholder {
  font-family: "New Century Schoolbook LT W01Rm", "Lucida Grande", sans-serif;
  color: #646464 !important;

  @media(max-width: 768px){
    font-size: 14px;
  }
}
`;

export default GlobalStyle;
